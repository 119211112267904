@charset "UTF-8";

:root {
  --defaultBrandColor: #e4c444;
  --defaultBorderColor: #c6c7c9;
  --defaultBgColor: #f7f7f7;
  --defaultBgColor2: #fff;
  --defaultComponentBgColor: #fff;
  --textColor: #333;
  --subTextColor: #666;
  --mutedTextColor: #999;
  --lightTextColor: #ccc;
  --lighterTextColor: #eee;
  --linkColor: #3291ea;
  --grayDark: #666;
  --grayLight: #ccc;
  --grayLighter: #eee;
  --whiteColor: #fff;
  --redColor: #e45454;
  --orangeColor: #e48e44;
  --blueColor: #64acd4;
  --purpleColor: #a494c4;
  --primaryColor: #137cbd;
  --successColor: #0f9960;
  --warningColor: #d9822b;
  --dangerColor: #db3737;
  --dangerColorAlpha: rgba(219, 55, 55, 0.5);
  --footerColor: #444;
  --footerTextLogoColor: var(--whiteColor);
  --relatedCafesBgColor: var(--whiteColor);
  --cafeCardBorderColor: rgba(0, 0, 0, 0.2);
  --articleCardBorderColor: rgba(0, 0, 0, 0.2);
  --readMoreButtonBorderColor: rgba(0, 0, 0, 0.2);
  --borderColor: rgba(0, 0, 0, 0.2);
  --borderDarkColor: rgba(0, 0, 0, 0.3);
  --inputColor: #fff;
  --inputBgColor: #fff;
  --inputBorderColor: rgba(0, 0, 0, 0.2);
  --inputBorderDarkColor: rgba(0, 0, 0, 0.3);
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  --transition: cubic-bezier(0.4, 0, 0.4, 1);
  --nomachiAvailableBackgroundColor: #ddeddf;
  --nomachiAvailableTextColor: #459c89;
  --nomachiFewLeftBackgroundColor: #fef4cb;
  --nomachiFewLeftTextColor: #a58c26;
  --nomachiCrowdedBackgroundColor: #fef4cb;
  --nomachiCrowdedTextColor: #a58c26;
  --nomachiInPreparationBackgroundColor: #fef4cb;
  --nomachiInPreparationTextColor: #a58c26;
  --nomachiCloseBackgroundColor: #e6e6e6;
  --nomachiCloseTextColor: #666666;
}

html[data-theme="dark"] {
  --defaultBgColor: #252525;
  --defaultBgColor2: #252525;
  --defaultComponentBgColor: #333;
  --textColor: #f7f7f7;
  --subTextColor: #d8d8d8;
  --mutedTextColor: #999;
  --borderColor: rgba(255, 255, 255, 0.2);
  --borderDarkColor: rgba(255, 255, 255, 0.3);
  --inputBgColor: #444;
  --inputBorderColor: rgba(0, 0, 0, 0.2);
  --inputBorderDarkColor: rgba(0, 0, 0, 0.3);
  --relatedCafesBgColor: var(--defaultBgColor);
  --cafeCardBorderColor: rgba(255, 255, 255, 0.2);
  --articleCardBorderColor: rgba(255, 255, 255, 0.2);
  --readMoreButtonBorderColor: rgba(255, 255, 255, 0.2);
}
